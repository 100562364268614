<template>
<div class="settingprofile">
  <div id="section-content" class="container container-fluid">
    <div class="bar-back">
      <router-link to="/member/main"><i class="fas fa-chevron-left"></i> หน้าหลัก</router-link>
    </div>
    <div
        class="p-2 m-0 w-100 bg-light  main-content"
        style="min-height: calc((100vh - 140px) - 50px)">
        <app-menu-profile/>
      <div class="bgwhitealpha text-secondary shadow-sm rounded p-2 px-2 xtarget col-lotto d-flex flex-row mb-1 pb-0">
        <div class="lotto-title">
          <h4>VIP</h4>
        </div>
      </div>
      <div class="bgwhitealpha text-secondary shadow-sm rounded p-2 mb-5 xtarget col-lotto">
        <div class="d-block text-center" id="section_vip"  ref="section_vip">
        <div class="card-body p-0">
          <div class="row justify-content-center mb-4 text-shadow">
            <div class="col-sm-6 col-md-4">
              <div class=" ">
                <div class="card-body p-0">
                  <p class="card-text text-center">
                    <strong>คะแนนของคุณ</strong>
                  </p>
                  <h6 class="card-title text-center">{{ numberWithCommas(getCreditTotal) }}</h6>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-4">
              <div class=" ">
                <div class="card-body  p-0">
                  <p class="card-text text-center">
                    <strong>สถานะของคุณ</strong>
                  </p>
                  <h6 class="card-title text-center">VIP {{ this.$store.getters.getVip }}</h6>
                </div>
              </div>
            </div>
          </div>
          <div class="row no-gutters mb-3" v-for="item in vip_credit" :key="item.id">
            <div class="col-3 text-right">{{ item.name }}</div>
            <div class="col-5" style="padding-left: 10px; padding-right: 10px">
              <b-progress :value="(getCreditTotal / item.credit) * 100" :max="100" class=""></b-progress>
            </div>
            <div class="col-4">{{ numberWithCommas(item.credit) }}</div>
          </div>
          <!-- <div class="text-center text-primary">
            <i class="fa fa-info-circle"></i> Jackpot รับเงิน 1,000,000 บาท
          </div> -->
        </div>
      </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import menu_profile from '@/components/menu_profile'
import { mapGetters, mapActions } from 'vuex'
export default {
  props: {
    username: {
      type: String,
      default: ''
    },
    vip_level: {
      default: 0
    }
  },
  components: {
    'app-menu-profile': menu_profile
  },
  mounted () {
    // location.href = '#section_vip'
    const labelInputRef = this.$refs.section_vip
    labelInputRef.focus()
  },
  data () {
    return {
      vip_credit: [
        { name: 'VIP 1', credit: 10000 },
        { name: 'VIP 2', credit: 100000 },
        { name: 'VIP 3', credit: 500000 },
        { name: 'VIP 4', credit: 1000000 },
        { name: 'VIP 5', credit: 10000000 },
        { name: 'Jackpot', credit: 100000000 }
      ]
    }
  },
  methods: {
    ...mapActions(['getBalanceInfo', 'setLoading']),
    numberWithCommas (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    getVip () {
      return this.$store.getters.getVip
    }
  },
  computed: {
    ...mapGetters(['getIsVip']),
    getCreditTotal () {
      return this.$store.getters.getCreditTotal
    }
  }
}
</script>

<style>
</style>
