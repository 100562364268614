<template>
     <div class=" rounded p-0 h-100">
        <div class="row px-0 mb-1 mx-0">
          <div class=" p-1 pb-0 d-flex" :class="[{ 'col-3': getIsVip },{ 'col-4': !getIsVip }]" >
            <router-link
              to="/setting/profile"
              class="btn-af btn btn-outline-custom btn-block d-flex flex-column p-1"
              :class="{ 'active lightSweep': isPageActive('profile') }"
            >
              <div class="affiliate-icon">
                <img src="@/assets/icon/management.png" class="-icon icon_mb_menu_profile" alt="">
              </div>
              บัญชีผู้ใช้
            </router-link>
          </div>
          <div class="col-3 p-1 pb-0 d-flex"  :class="[{ 'col-3': getIsVip },{ 'col-4': !getIsVip }]" >
            <router-link
              :to="hasBank ? '/member/settings/bank' : '/member/settings/bank/add'"
              class="
                btn-af btn btn-outline-custom btn-block d-flex flex-column p-1"
                :class="{ 'active lightSweep': isPageActive('member/settings/bank') }"
            >
              <div class="affiliate-icon">
                <img src="@/assets/icon/bank.png" class="-icon icon_mb_menu_profile" alt="Menu icon promotion">
              </div>
              บัญชีธนาคาร
            </router-link>
          </div>
          <div class="col-3 p-1 pb-0 d-flex"  :class="[{ 'col-3': getIsVip },{ 'col-4': !getIsVip }]" >
            <router-link
              to="/member/gem"
              class="btn-af btn btn-outline-custom btn-block d-flex flex-column p-1"
              :class="{ 'active lightSweep': isPageActive('member/gem') }"
            >
              <div class="affiliate-icon">
                <img src="@/assets/icon/slot-machine.png" class="-icon icon_mb_menu_profile" alt="Menu icon promotion">
              </div>
              เพชร
            </router-link>
          </div>
          <div class="col-3 p-1 pb-0 d-flex"  v-if="getIsVip">
            <router-link
              to="/member/vip"
              class="btn-af btn btn-outline-custom btn-block d-flex flex-column p-1"
              :class="{ 'active lightSweep': isPageActive('member/vip') }"
            >
              <div class="affiliate-icon">
                <img src="@/assets/icon/vip.png" class="-icon icon_mb_menu_profile" alt="Menu icon promotion">
              </div>
              VIP
            </router-link>
          </div>
        </div>
      </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
export default {
  props: {
  },
  ...mapActions(['getBalanceInfo', 'setLoading']),
  data () {
    return {
    }
  },
  methods: {
    isPageActive (pageName) {
      console.log(this.$route.name)
      return (this.$route.name === pageName)
    }
  },
  computed: {
    ...mapGetters(['getIsVip']),
    ...mapState({
      hasBank: state => state.bankModule.hasBank
    })
  }
}
</script>

<style>
.icon_mb_menu_profile{
  width: 30px;
  height: auto;
}
.btn-outline-custom{
  color: #050505;
    border-color: #444546;
}
.btn-outline-custom:not(:disabled):not(.disabled):active, .btn-outline-custom:not(:disabled):not(.disabled).active, .show > .btn-outline-custom.dropdown-toggle {
  color: #fff;
    background-color: #1e1e1e;
    border-color: #000000;
}
</style>
